import { useId, useState } from "react";
import { FadeIn } from "../components/utils/FadeIn";
import { Button } from "../components/utils/Button";
import { Offices } from "../components/utils/Offices";
import { Border } from "../components/utils/Border";
import { SocialMedia } from "../components/utils/SocialMedia";
import { PageIntro } from "./PageIntro";
import { Container } from "../components/utils/Container";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";

function TextInput({ label, ...props }) {
  let id = useId();

  return (
    <div className="group relative z-0 transition-all focus-within:z-10">
      <input
        type="text"
        id={id}
        {...props}
        placeholder=" "
        className="peer block w-full border border-neutral-300 bg-transparent px-6 pb-4 pt-12 text-base/6 text-neutral-200 ring-4 ring-transparent transition focus:border-orange-500 focus:outline-none focus:ring-orange-600/5 group-first:rounded-t-2xl group-last:rounded-b-2xl"
      />
      <label
        htmlFor={id}
        className="pointer-events-none absolute left-6 top-1/2 -mt-3 origin-left text-base/6 text-neutral-300 transition-all duration-200 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:font-semibold peer-focus:text-neutral-300 peer-[:not(:placeholder-shown)]:-translate-y-4 peer-[:not(:placeholder-shown)]:scale-75 peer-[:not(:placeholder-shown)]:font-semibold peer-[:not(:placeholder-shown)]:text-neutral-300"
      >
        {label}
      </label>
    </div>
  );
}

function RadioInput({ label, ...props }) {
  return (
    <label className="flex gap-x-3">
      <input
        type="radio"
        {...props}
        className="h-6 w-6 flex-none appearance-none rounded-full border border-orange-600/20 outline-none checked:border-[0.5rem] checked:border-orange-700 focus-visible:ring-1 focus-visible:ring-orange-600 focus-visible:ring-offset-2"
      />
      <span className="text-base/6 text-neutral-950">{label}</span>
    </label>
  );
}

function ContactForm({ setSent }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [load, setLoad] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(false);
    await addDoc(collection(db, "contact"), {
      email,
      name,
      phone,
      company,
      message,
      status: "pending",
      creatAt: moment.utc().unix(),
    }).then(() => {
      setLoad(true);
      setSent(true);
      setTimeout(() => {
        window.location.reload();
      }, 8000);
    });
  };

  return (
    <FadeIn className="lg:order-last">
      <form onSubmit={handleSubmit}>
        <h2 className="font-display text-base font-semibold text-neutral-300">
          Work inquiries
        </h2>
        <div className="isolate mt-6 -space-y-px rounded-2xl ">
          <TextInput
            onChange={(e) => setName(e.target.value)}
            label="Name"
            name="name"
            autoComplete="name"
          />
          <TextInput
            label="Email"
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
          <TextInput
            label="Company"
            name="company"
            onChange={(e) => setCompany(e.target.value)}
            autoComplete="organization"
          />
          <TextInput
            label="Phone"
            onChange={(e) => setPhone(e.target.value)}
            type="tel"
            name="phone"
            autoComplete="tel"
          />
          <TextInput
            label="Message"
            onChange={(e) => setMessage(e.target.value)}
            name="message"
          />
        </div>
        <Button type="submit" className="mt-10">
          {!load && (
            <svg
              aria-hidden="true"
              role="status"
              class="inline w-8 h-8 me-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          )}
          {load && <span> Let’s work together</span>}
        </Button>
      </form>
    </FadeIn>
  );
}

function ContactDetails({ sent }) {
  return (
    <FadeIn>
      <h2 className="font-display text-base font-semibold text-neutral-300">
        Office Addresses
      </h2>
      <p className="mt-6 text-base text-neutral-300">
        Prefer doing things in person? We don’t but we have to list our
        addresses here for legal reasons.
      </p>

      {sent && (
        <div className="col-span-full flex items-center justify-center">
          <img
            src={require("../assets/illustrations/check_12484055.png")}
            className="h-40 w-40 my-10  animate-bounce self-center"
            alt=""
            srcset=""
          />
        </div>
      )}

      <Offices invert className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2" />

      <Border invert className="mt-16 pt-16">
        <h2 className="font-display text-base font-semibold text-neutral-300">
          Email us
        </h2>
        <dl className="mt-6 grid grid-cols-1 gap-8 text-sm sm:grid-cols-2">
          {[
            ["Info", "info@e-solutions.ae"],
            ["Jobs", "help@e-solutions.ae"],
          ].map(([label, email]) => (
            <div key={email}>
              <dt className="font-semibold text-orange-600">{label}</dt>
              <dd>
                <a
                  href={`mailto:${email}`}
                  className="text-neutral-400 hover:text-neutral-950"
                >
                  {email}
                </a>
              </dd>
            </div>
          ))}
        </dl>
      </Border>

      <Border className="mt-16 pt-16" invert>
        <h2 className="font-display text-base font-semibold text-neutral-300">
          Follow us
        </h2>
        <SocialMedia invert className="mt-6" />
      </Border>
    </FadeIn>
  );
}

export const metadata = {
  title: "Contact Us",
  description: "Let’s work together. We can’t wait to hear from you.",
};

export default function Contact() {
  const [sent, setSent] = useState(false);

  return (
    <div className="relative overflow-hidden">
      <div className="absolute w-[700px] h-[700px] rounded-full  z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />

      <div className="py-32 relative  overflow-hidden">
        <PageIntro
          eyebrow="Contact us"
          title="Let’s work"
          coloredText={"together"}
          blog
        >
          <p>Your business differentiator</p>
        </PageIntro>

        <Container className="mt-24 sm:mt-32 lg:mt-40 z-40">
          <div className="grid grid-cols-1 gap-x-8 gap-y-24 lg:grid-cols-2">
            <ContactForm setSent={setSent} />
            <ContactDetails sent={sent} />
          </div>
        </Container>
      </div>
    </div>
  );
}
