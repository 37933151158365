import { useEffect, useRef, useState } from "react";
import { ContactSection } from "../components/utils/ContactSection";
import { FadeIn } from "../components/utils/FadeIn";
import VideoBackground from "./videobackground";
import InsightSection from "./insight-section";
import LatestOpportunities from "./latest-opportunity";
import JoinUs from "./join-us";
import Seeking from "./seeking-talent";
import SearchBar from "../components/utils/search-bar";
import Testimonal from "./testimonial";
import { storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import Ebooks from "./ebooks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showB, setShowB] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    (async () => {
      const gsReferenceVideo = ref(
        storage,
        "gs://e-solutions-25db2.appspot.com/e-solutions.mp4"
      );

      await getDownloadURL(gsReferenceVideo)
        .then((url) => {
          setVideoUrl(url);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    })();
  }, []);

  return (
    <main className="  scroll-smooth  overflow-hidden   bg-gradient-to-br from-orange-700/5   ">
      {/* Hero section */}
      <VideoBackground
        className="  
          min-h-lvh w-screen"
      />
      {/**  section job */}
      <SearchBar />
      <LatestOpportunities />
      <Testimonal />
      <div className="border-t-4 px-4 py-10 border-t-orange-600">
        <div class="max-w-[85rem] relative  px-10 xl:px-0  mx-auto  flex justify-center items-center">
          <div className=" absolute inset-0 -z-0 bg-gradient-to-r blur-2xl from-orange-600 to-violet-800"></div>

          {showB && (
            <div class="absolute z-20 inset-0 size-full">
              <div class="flex flex-col justify-center items-center size-full">
                <a
                  class="py-3 px-4 cursor-pointer inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                  onClick={() => {
                    setShowB(false);
                    videoRef.current.play();
                  }}
                >
                  <svg
                    class="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polygon points="5 3 19 12 5 21 5 3" />
                  </svg>
                  Play the overview
                </a>
              </div>
            </div>
          )}

          <video
            className="rounded-4xl aspect-video z-10 p-4 object-cover  w-full"
            controls
            ref={videoRef}
            poster={require("../assets/illustrations/wwa.png")}
            preload="auto"
            playsinline
            onPause={() => setShowB(true)}
            src={videoUrl}
          ></video>

          {/*  <iframe
            className=" rounded-lg aspect-video z-10 p-4 object-cover  w-full "
            src={videoUrl}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe> */}
        </div>
      </div>
      <Seeking />
      <FadeIn class="relative px-4 py-10 sm:px-6 border-t-4 border-t-orange-600 lg:px-8 lg:py-14 mx-auto">
        <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 -left-20 bg-indigo-800 opacity-30 blur-3xl" />
        <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />
        <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />

        <blockquote class="text-center  max-w-[85rem] lg:mx-auto lg:w-3/5">
          <svg
            className="mx-auto w-20 h-auto sm:w-28 fill-gray-200"
            viewBox="0 0 81 90"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.15924 18.8315L17.0255 27.8804L42.0478 14.1848L64.2325 27.8804L75.3248 18.8315L42.0478 0L5.15924 18.8315Z" />
            <path d="M0 27.8804L12.6401 36.6848V60.8967L42.0478 76.7935L81 53.0707V66.2772L42.0478 90L0 66.2772V27.8804Z" />
            <path d="M22.7006 55.0272V43.0435L42.0478 55.0272L81 29.5924V40.3533L42.0478 66.2772L22.7006 55.0272Z" />
            <path d="M25.7962 32.7717L42.0478 23.7228L55.9777 32.7717L42.0478 43.0435L25.7962 32.7717Z" />
          </svg>

          <div class="mt-6 lg:mt-10">
            <p class="relative text-xl sm:text-2xl md:text-3xl md:leading-normal font-medium text-gray-800">
              <svg
                class="absolute top-0 sm:block hidden start-0 transform -translate-x-8 -translate-y-8 size-16  sm:h-24 sm:w-24 text-gray-700"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
                  fill="currentColor"
                />
              </svg>
              <span class="relative z-10 italic  text-gray-200">
                Acquiring the right talent is the most important key to growth.
                Hiring was, and still is, the most important thing we do.
              </span>
            </p>
          </div>
          <footer class="mt-6">
            <div class="font-semibold text-gray-200">Marc Benioff</div>
            <div class="text-sm text-gray-500">
              Founder, Chairman & co-CEO | Salesforce
            </div>
          </footer>
        </blockquote>
      </FadeIn>

      <JoinUs />
      <Ebooks />
      <div className="border-t-4 border-t-orange-600">
        <div class="max-w-[85rem] px-4 xl:px-0  mx-auto ">
          <InsightSection
            all={false}
            color={"orange"}
            className="  w-full   relative py-14   "
          />
        </div>
      </div>
      <ContactSection />
    </main>
  );
}
